// 入口文件
// import "./mock";
import Vue from "vue";
import App from "./App.vue";
import "./utils/typeSurpriseUse"
import "./styles/global.less";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
const toastOptions = {
  position: 'bottom-right',
  timeout: 2000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: 'button',
  icon: true,
  rtl: false,
};
import router from "./router";
import showMessage from "./utils/showMessage";
import "./eventBus";
import store from "./store";
store.dispatch("setting/fetchSetting");
Vue.prototype.$showMessage = showMessage;

// 注册全局指令
import vLoading from "./directives/loading";
import vLazy from "./directives/lazy";
Vue.directive("loading", vLoading);
Vue.directive("lazy", vLazy);
Vue.use(Toast, toastOptions);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
