import { render, staticRenderFns } from "./NotFound.vue?vue&type=template&id=4252a629&scoped=true"
import script from "./NotFound.vue?vue&type=script&lang=js"
export * from "./NotFound.vue?vue&type=script&lang=js"
import style0 from "./NotFound.vue?vue&type=style&index=0&id=4252a629&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_d02125917f7c66a571cdcbfa3dd68341/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4252a629",
  null
  
)

export default component.exports