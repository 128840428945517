<template>
	<div class="app-container">
		<Layout>
			<template #left>
				<div class="nav-aside">
					<SiteAside />
				</div>
			</template>
			<template #default>
				<RouterView />
			</template>
		</Layout>
		<ToTop />
		<MySprite />
		<div class="nav-bar-container">
			<NavBar :currentPagePath="currentPagePath"/>
		</div>
	</div>
</template>

<script>
	import Layout from './components/Layout/index.vue';
	import SiteAside from './components/SiteAside';
	import ToTop from './components/ToTop';
	import MySprite from './components/MySprite/index.vue';
	import NavBar from './components/NavBar/index.vue';
	export default {
		components: {
			Layout,
			SiteAside,
			ToTop,
			MySprite,
			NavBar,
		},
		data() {
			return {
				currentPagePath: '/',    // 默认为首页
			};
		},
		watch: {
			$route(to) {
				this.currentPagePath = to.path;
			},
		},
	};
</script>

<style lang="less">
	@import '~@/styles/mixin.less';
	a {
		cursor: url('../src/assets/mouse_tail_pink.png'), default;
	}
	.app-container {
		.self-fill(fixed);
		cursor: url('../src/assets/mouse_tail_cyan.png'), default;
	}
	::selection {
		background-color: #eb5055;
		color: #fff;
	}
	.nav-aside {
		width: 250px;
		height: 100%;
	}
	
	.margin-l10 {
		margin-left: 10px;
	}

	.margin-l15 {
		margin-left: 15px;
	}

	.margin-l20 {
		margin-left: 20px;
	}

	.margin-l36 {
		margin-left: 36px;
	}

	.margin-t10 {
		margin-top: 10px;
	}

	.margin-t15 {
		margin-top: 15px;
	}

	.margin-t20 {
		margin-top: 20px;
	}

	.margin-t40 {
		margin-top: 40px;
	}

	.wid_hei_30_30 {
		width: 30px;
		height: 30px;
	}

	.wid_hei_40_40 {
		width: 40px;
		height: 40px;
	}

	.wid_hei_80_80 {
		width: 80px;
		height: 80px;
	}

	.wid_hei_100_100 {
		width: 100px;
		height: 100px;
	}

	.flex {
		display: flex;
	}

	.flex-wrap {
		flex-wrap: wrap;
	}

	.flex-shink-0 {
		flex-shrink: 0;
	}

	.flex-c {
		display: flex;
		align-items: center;
	}

	.flex-cc {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.flex-csb {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.flex-csa {
		display: flex;
		align-items: center;
		justify-content: space-around;
	}

	.flex-column-c {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.flex-column-cc {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.radius_5 {
		border-radius: 5px;
	}

	.radius_per50 {
		border-radius: 50%;
	}

	.nav-bar-container {
		display: none;
	}

	.padding-15 {
		padding: 15px;
	}

	.padding-20 {
		padding: 20px;
	}

	.padding-40 {
		padding: 40px;
	}

	.font-15 {
		font-size: 15px;
	}

	.font-18 {
		font-size: 18px;
	}

	.font-20 {
		font-size: 20px;
	}

	.font-25 {
		font-size: 25px;
	}

	.font-30 {
		font-size: 30px;
	}

	.color-0000ff {
		color: #0000ff;
	}

	@media screen and (max-width: 960px) {
		.nav-aside {
			width: 0px;
		}
		.nav-bar-container {
			display: block;
		}
	}
</style>
