<template>
	<div class="nav-bar" :class="[currentPagePath === '/' ? 'transparent-bg' : '']">
		<div v-if="isShowMenuWrapper" @click="clickNavBarButton" class="opcity-mask-all-screen" />
		<img
			@click="clickNavBarButton"
			class="nav-bar-button"
			src="@/assets/icon/nav-bar-icon.png"
			alt=""
		/>
		<div v-if="isShowMenuWrapper" @click="clickNavBarButton" class="menu-wrapper">
			<div class="triangle" />
			<RouterLink
				:exact="item.exact"
				v-for="item in menuItemArr"
				:key="item.link"
				:to="{ name: item.name }"
				active-class="selected"
				exact-active-class=""
				class="flex-c padding-15"
			>
				<div class="icon">
					<Icon :type="item.icon" />
				</div>
				<div>{{ item.title }}</div>
			</RouterLink>
		</div>
	</div>
</template>
<script>
	import Icon from '@/components/Icon';
	export default {
		components: {
			Icon,
		},
		props: {
			currentPagePath: String
		},
		data() {
			return {
				isShowMenuWrapper: false,
				menuItemArr: [
					{
						name: 'Home',
						title: '首页',
						icon: 'home',
						exact: true,
					},
					{
						name: 'Blog',
						title: '文章',
						icon: 'blog',
						exact: false, // 激活状态是否要精确匹配
					},
					{
						name: 'About',
						title: '关于我',
						icon: 'about',
						exact: true,
					},
					{
						name: 'Project',
						title: '项目&效果',
						icon: 'code',
						exact: true,
					},
					{
						name: 'Message',
						title: '留言板',
						icon: 'chat',
						exact: true,
					},
				],
			};
		},
		methods: {
			clickNavBarButton() {
				this.isShowMenuWrapper = !this.isShowMenuWrapper;
			},
		},
	};
</script>
<style lang="less" scoped>
	.nav-bar {
		position: fixed;
		top: 0px;
		right: 0px;
		width: 100vw;
		height: 47px;
		box-sizing: border-box;
		background: #fff;
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
		padding: 11px 16px;
		.opcity-mask-all-screen {
			width: 100vw;
			height: 100vh;
			position: fixed;
			left: 0;
			top: 0;
			z-index: 10;
		}
		.nav-bar-button {
			position: fixed;
			top: 8px;
			right: 20px;
			letter-spacing: 3px;
			transform: scale(0.6);
			z-index: 11;
		}
		.menu-wrapper {
			width: 155px;
			position: fixed;
			z-index: 11;
			top: 55px;
			right: 10px;
			background-color: #4c4c4c;
			color: #fff;
			border-radius: 7px;
			.icon {
				width: 24px;
			}
			.triangle {
				position: fixed;
				top: 50px;
				background-color: #4c4c4c;
				right: 26px;
				width: 20px;
				height: 20px;
				border-radius: 2px;
				transform: rotate(45deg);
				z-index: 11;
			}
		}
	}
	.transparent-bg {
		background: transparent;
		box-shadow: none;
	}
</style>
