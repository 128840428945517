<template>
  <div class="site-aside-container">
    <template v-if="data">
      <Avatar :url="data.avatar" />
      <h1 class="title">{{ data.siteTitle }}</h1>
    </template>

    <Menu />
    <Contact v-if="data" />
    <p class="footer">湘ICP备2022017967号</p>
    <div class="visitor-log"><RouterLink to="visitLog">来访统计</RouterLink></div>
  </div>
</template>

<script>
import Avatar from "@/components/Avatar";
import Menu from "./Menu";
import Contact from "./Contact";
import { mapState } from "vuex";
export default {
  components: {
    Avatar,
    Menu,
    Contact,
  },
  computed: mapState("setting", ["data"]),
};
</script>

<style scoped lang="less">
@import "~@/styles/var.less";
.site-aside-container {
  width: 100%;
  height: 100%;
  background: @dark;
  padding: 20px 0;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: auto;
}
.avatar-container {
  margin: 0 auto;
}
.footer {
  position: fixed;
  left: 57px;
  bottom: 20px;
  text-align: center;
  font-size: 12px;
}
.visitor-log {
  position: fixed;
  left: 95px;
  bottom: 10px;
  text-align: center;
  font-size: 12px;
  color: #3b90d1;
}
.title {
  font-size: 1.2em;
  color: #fff;
  text-align: center;
}
@media screen and (max-width: 960px) {
  .nav-aside {
    width: 0px;
  }
  .nav-bar-container {
    display: block;
  }
  .footer {
    display: none;
  }
  .visitor-log {
    display: none;
  }
}
</style>
