<template>
	<div id="landlord" draggable="true">
		<div id="test">
			<div class="message" style="opacity: 0"></div>
			<canvas
				id="live2d"
				width="185"
				height="174"
				class="live2d"
			></canvas>
		</div>
		<div class="hide-button">隐藏</div>
	</div>
</template>
<script>
	import { live2dFunc } from './live2d';
	import { live2dMoveFunc } from './live2dMove';
	export default {
		data() {
			return {};
		},
		mounted() {
			live2dFunc();
			live2dMoveFunc();
		},
	};
</script>
<style lang="less" scoped>
	#landlord {
		user-select: none;
		position: fixed;
		left: 26px;
		bottom: 60px;
		width: 185px;
		height: 174px;
		z-index: 1000;
		font-size: 0;
		transition: all 0.3s ease-in-out;
	}

	#live2d {
		position: relative;
	}

	.message {
		opacity: 0;
		width: 200px;
		height: auto;
		margin: auto;
		padding: 7px;
		top: -75px;
		left: -10px;
		text-align: center;
		border: 1px solid #b4b8bc;
		border-radius: 12px;
		background-color: rgba(255, 137, 255, 0.2);
		box-shadow: 0 3px 15px 2px #b4b8bc;
		color: #b4b8bc;
		font-size: 13px;
		font-weight: 400;
		text-overflow: ellipsis;
		text-transform: uppercase;
		overflow: hidden;
		position: absolute;
		animation-delay: 5s;
		animation-duration: 50s;
		animation-iteration-count: infinite;
		animation-name: shake;
		animation-timing-function: ease-in-out;
	}

	.hide-button {
		position: absolute;
		bottom: 0px;
		right: -20px;
		display: none;
		overflow: hidden;
		width: 60px;
		height: 20px;
		line-height: 20px;
		border: 1px solid #b4b8bc;
		border-radius: 12px;
		background: rgba(255, 137, 255, 0.2);
		box-shadow: 0 3px 15px 2px #b4b8bc;
		text-align: center;
		font-size: 12px;
		cursor: pointer;
	}

	.hide-button:hover {
		border: 1px solid #f4a7b9;
		background: #f4f6f8;
	}

	/* @media (max-width: 860px) {
    #landlord {
        display: none;
    }
} */

	@keyframes shake {
		2% {
			transform: translate(0.5px, -1.5px) rotate(-0.5deg);
		}

		4% {
			transform: translate(0.5px, 1.5px) rotate(1.5deg);
		}

		6% {
			transform: translate(1.5px, 1.5px) rotate(1.5deg);
		}

		8% {
			transform: translate(2.5px, 1.5px) rotate(0.5deg);
		}

		10% {
			transform: translate(0.5px, 2.5px) rotate(0.5deg);
		}

		12% {
			transform: translate(1.5px, 1.5px) rotate(0.5deg);
		}

		14% {
			transform: translate(0.5px, 0.5px) rotate(0.5deg);
		}

		16% {
			transform: translate(-1.5px, -0.5px) rotate(1.5deg);
		}

		18% {
			transform: translate(0.5px, 0.5px) rotate(1.5deg);
		}

		20% {
			transform: translate(2.5px, 2.5px) rotate(1.5deg);
		}

		22% {
			transform: translate(0.5px, -1.5px) rotate(1.5deg);
		}

		24% {
			transform: translate(-1.5px, 1.5px) rotate(-0.5deg);
		}

		26% {
			transform: translate(1.5px, 0.5px) rotate(1.5deg);
		}

		28% {
			transform: translate(-0.5px, -0.5px) rotate(-0.5deg);
		}

		30% {
			transform: translate(1.5px, -0.5px) rotate(-0.5deg);
		}

		32% {
			transform: translate(2.5px, -1.5px) rotate(1.5deg);
		}

		34% {
			transform: translate(2.5px, 2.5px) rotate(-0.5deg);
		}

		36% {
			transform: translate(0.5px, -1.5px) rotate(0.5deg);
		}

		38% {
			transform: translate(2.5px, -0.5px) rotate(-0.5deg);
		}

		40% {
			transform: translate(-0.5px, 2.5px) rotate(0.5deg);
		}

		42% {
			transform: translate(-1.5px, 2.5px) rotate(0.5deg);
		}

		44% {
			transform: translate(-1.5px, 1.5px) rotate(0.5deg);
		}

		46% {
			transform: translate(1.5px, -0.5px) rotate(-0.5deg);
		}

		48% {
			transform: translate(2.5px, -0.5px) rotate(0.5deg);
		}

		50% {
			transform: translate(-1.5px, 1.5px) rotate(0.5deg);
		}

		52% {
			transform: translate(-0.5px, 1.5px) rotate(0.5deg);
		}

		54% {
			transform: translate(-1.5px, 1.5px) rotate(0.5deg);
		}

		56% {
			transform: translate(0.5px, 2.5px) rotate(1.5deg);
		}

		58% {
			transform: translate(2.5px, 2.5px) rotate(0.5deg);
		}

		60% {
			transform: translate(2.5px, -1.5px) rotate(1.5deg);
		}

		62% {
			transform: translate(-1.5px, 0.5px) rotate(1.5deg);
		}

		64% {
			transform: translate(-1.5px, 1.5px) rotate(1.5deg);
		}

		66% {
			transform: translate(0.5px, 2.5px) rotate(1.5deg);
		}

		68% {
			transform: translate(2.5px, -1.5px) rotate(1.5deg);
		}

		70% {
			transform: translate(2.5px, 2.5px) rotate(0.5deg);
		}

		72% {
			transform: translate(-0.5px, -1.5px) rotate(1.5deg);
		}

		74% {
			transform: translate(-1.5px, 2.5px) rotate(1.5deg);
		}

		76% {
			transform: translate(-1.5px, 2.5px) rotate(1.5deg);
		}

		78% {
			transform: translate(-1.5px, 2.5px) rotate(0.5deg);
		}

		80% {
			transform: translate(-1.5px, 0.5px) rotate(-0.5deg);
		}

		82% {
			transform: translate(-1.5px, 0.5px) rotate(-0.5deg);
		}

		84% {
			transform: translate(-0.5px, 0.5px) rotate(1.5deg);
		}

		86% {
			transform: translate(2.5px, 1.5px) rotate(0.5deg);
		}

		88% {
			transform: translate(-1.5px, 0.5px) rotate(1.5deg);
		}

		90% {
			transform: translate(-1.5px, -0.5px) rotate(-0.5deg);
		}

		92% {
			transform: translate(-1.5px, -1.5px) rotate(1.5deg);
		}

		94% {
			transform: translate(0.5px, 0.5px) rotate(-0.5deg);
		}

		96% {
			transform: translate(2.5px, -0.5px) rotate(-0.5deg);
		}

		98% {
			transform: translate(-1.5px, -1.5px) rotate(-0.5deg);
		}

		0%,
		100% {
			transform: translate(0, 0) rotate(0);
		}
	}

	@media screen and (max-width: 960px) {
		#landlord {
			display: none;
		}
	}
</style>
