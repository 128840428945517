<template>
	<nav class="menu-container">
		<div class="qq-login-img-wrapper">
			<img
				@click="qqLogin"
				id="qqLoginBtn"
				class="qq-login-img"
				src="@/assets/bt_white_76X24.png"
				alt=""
			/>
		</div>
		<RouterLink
			:exact="item.exact"
			v-for="item in items"
			:key="item.link"
			:to="{ name: item.name }"
			active-class="selected"
			exact-active-class=""
		>
			<div class="icon">
				<Icon :type="item.icon" />
			</div>
			<span>{{ item.title }}</span>
		</RouterLink>
	</nav>
</template>

<script>
	import Icon from '@/components/Icon';
	export default {
		components: {
			Icon,
		},
		data() {
			return {
				items: [
					{
						name: 'Home',
						title: '首页',
						icon: 'home',
						exact: true,
					},
					{
						name: 'Blog',
						title: '文章',
						icon: 'blog',
						exact: false, // 激活状态是否要精确匹配
					},
					{
						name: 'About',
						title: '关于我',
						icon: 'about',
						exact: true,
					},
					{
						name: 'Project',
						title: '项目&效果',
						icon: 'code',
						exact: true,
					},
					{
						name: 'Message',
						title: '留言板',
						icon: 'chat',
						exact: true,
					},
				],
			};
		},
		methods: {
			qqLogin() {
				const redirectURI = encodeURIComponent('https://www.xiaocantongxue.xyz/qqlogin')
				QC.Login.showPopup({
					appId: '102042228',
					redirectURI,
				});
			},
		},
	};
</script>

<style scoped lang="less">
	@import '~@/styles/var.less';
	.menu-container {
		color: @gray;
		margin: 24px 0;
		.qq-login-img-wrapper {
			padding: 0 0px 20px 50px;
			.qq-login-img {
				width: 76px;
				height: 24px;
				margin-left: 39px;
			}
		}
		a {
			&.selected {
				background: darken(@words, 3%);
			}
			padding: 0 50px;
			display: block;
			display: flex;
			align-items: center;
			height: 45px;
			.icon {
				width: 24px;
			}
			&:hover {
				color: #fff;
			}
		}
	}
</style>
