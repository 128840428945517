<template>
	<div @click="handleClick" v-show="show" class="to-top-container">
		Top
	</div>
</template>

<script>
	export default {
		data() {
			return {
				show: false,
			};
		},
		created() {
			this.$bus.$on('mainScroll', this.handleScroll);
		},
		destroyed() {
			this.$bus.$off('mainScroll', this.handleScroll);
		},
		methods: {
			handleScroll(dom) {
				if (!dom) {
					this.show = false;
					return;
				}
				this.show = dom.scrollTop >= 500;
			},
			handleClick() {
				// 回到顶部
				this.$bus.$emit('setMainScroll', 0);
			},
		},
	};
</script>

<style scoped lang="less">
	@import '~@/styles/var.less';
	.to-top-container {
		background: @primary;
		width: 50px;
		height: 50px;
		border-radius: 50%;
		position: fixed;
		z-index: 99;
		right: 50px;
		bottom: 50px;
		line-height: 50px;
		color: #fff;
		text-align: center;
	}
	@media screen and (max-width: 960px) {
    .to-top-container {
      right: 25px;
    }
	}
</style>
