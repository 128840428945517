export default function(fn, duration = 100, that) {
  let timer = null;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      console.log(fn);
      fn.call(that);
    }, duration);
  };
}
